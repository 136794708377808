<ion-content>
  <div *ngIf="type == 'call'">
    <h1>اختر طريقة التواصل</h1>
    <div class="actions_wrapper">
      <div class="action_item" (click)="setPaymentType('bank')">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2748_37960)"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.4857 1.66618H6.15234C5.69211 1.66618 5.31901 2.03927 5.31901 2.49951V17.4995C5.31901 17.9597 5.69211 18.3328 6.15234 18.3328H14.4857C14.9459 18.3328 15.319 17.9597 15.319 17.4995V2.49951C15.319 2.03927 14.9459 1.66618 14.4857 1.66618ZM6.15234 -0.000488281C4.77163 -0.000488281 3.65234 1.1188 3.65234 2.49951V17.4995C3.65234 18.8802 4.77163 19.9995 6.15234 19.9995H14.4857C15.8664 19.9995 16.9857 18.8802 16.9857 17.4995V2.49951C16.9857 1.1188 15.8664 -0.000488281 14.4857 -0.000488281H6.15234Z" fill="#6529F7"/><path d="M11.151 15.8328C11.151 16.2931 10.7779 16.6662 10.3177 16.6662C9.85747 16.6662 9.48438 16.2931 9.48438 15.8328C9.48438 15.3726 9.85747 14.9995 10.3177 14.9995C10.7779 14.9995 11.151 15.3726 11.151 15.8328Z" fill="#6529F7"/></g><defs><clipPath id="clip0_2748_37960"><rect width="20" height="20" fill="white" transform="translate(0.318359 -0.000488281)"/></clipPath></defs></svg>
        <p>مكالمة جوال</p>
        <svg class="arrow" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8887 7.79289C15.2793 8.18342 15.2793 8.81658 14.8887 9.20711L12.0959 12L14.8887 14.7929C15.2793 15.1834 15.2793 15.8166 14.8887 16.2071C14.4982 16.5976 13.8651 16.5976 13.4745 16.2071L9.97453 12.7071C9.58401 12.3166 9.58401 11.6834 9.97453 11.2929L13.4745 7.79289C13.8651 7.40237 14.4982 7.40237 14.8887 7.79289Z" fill="#777E91"/></svg>
      </div>
      <div class="action_item" (click)="setPaymentType('stc_pay')">
        <img width="72" src="/assets/zoom.png" />
        <svg class="arrow" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8887 7.79289C15.2793 8.18342 15.2793 8.81658 14.8887 9.20711L12.0959 12L14.8887 14.7929C15.2793 15.1834 15.2793 15.8166 14.8887 16.2071C14.4982 16.5976 13.8651 16.5976 13.4745 16.2071L9.97453 12.7071C9.58401 12.3166 9.58401 11.6834 9.97453 11.2929L13.4745 7.79289C13.8651 7.40237 14.4982 7.40237 14.8887 7.79289Z" fill="#777E91"/></svg>
      </div>
    </div>
  </div>
  <div *ngIf="type == 'transfer' && payment_type == ''">
    <h1>إعداد طريقة تحويل</h1>
    <div class="actions_wrapper">
      <div class="action_item" (click)="setPaymentType('bank')">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.48438 15V7.5H9.48437V15H4.48438ZM7.81771 9.16667V13.3333H6.15104V9.16667H7.81771Z" fill="#6529F7"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.151 15V7.5H16.151V15H11.151ZM14.4844 9.16667V13.3333H12.8177V9.16667H14.4844Z" fill="#6529F7"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.151 14.9999H4.48438C4.02414 14.9999 3.65104 15.373 3.65104 15.8333C3.65104 16.2935 4.02414 16.6666 4.48437 16.6666H16.151C16.6113 16.6666 16.9844 16.2935 16.9844 15.8333C16.9844 15.373 16.6113 14.9999 16.151 14.9999ZM4.48438 13.3333C3.10366 13.3333 1.98438 14.4525 1.98438 15.8333C1.98438 17.214 3.10366 18.3333 4.48437 18.3333H16.151C17.5318 18.3333 18.651 17.214 18.651 15.8333C18.651 14.4525 17.5318 13.3333 16.151 13.3333H4.48438Z" fill="#6529F7"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.9844 6.66678V6.02686C16.9844 5.65885 16.743 5.33441 16.3905 5.22867L10.5572 3.47867C10.401 3.43181 10.2345 3.43181 10.0783 3.47867L4.24492 5.22867C3.89243 5.33441 3.65104 5.65885 3.65104 6.02686V6.66678C3.65104 7.12702 4.02414 7.50011 4.48437 7.50011H16.151C16.6113 7.50011 16.9844 7.12702 16.9844 6.66678ZM3.76601 3.63229C2.70855 3.94953 1.98438 4.92283 1.98438 6.02686V6.66678C1.98438 8.04749 3.10366 9.16678 4.48437 9.16678H16.151C17.5318 9.16678 18.651 8.04749 18.651 6.66678V6.02686C18.651 4.92284 17.9269 3.94953 16.8694 3.63229L11.0361 1.88229C10.5675 1.74171 10.0679 1.74171 9.59934 1.88229L3.76601 3.63229Z" fill="#6529F7"/></svg>
        <p>حساب بنكي</p>
        <svg class="arrow" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8887 7.79289C15.2793 8.18342 15.2793 8.81658 14.8887 9.20711L12.0959 12L14.8887 14.7929C15.2793 15.1834 15.2793 15.8166 14.8887 16.2071C14.4982 16.5976 13.8651 16.5976 13.4745 16.2071L9.97453 12.7071C9.58401 12.3166 9.58401 11.6834 9.97453 11.2929L13.4745 7.79289C13.8651 7.40237 14.4982 7.40237 14.8887 7.79289Z" fill="#777E91"/></svg>
      </div>
      <div class="action_item" (click)="setPaymentType('stc_pay')">
        <div style="display: flex; justify-content: flex-start; align-items: center;">
          <img width="72" src="/assets/stc_pay.png" />
          <!-- <img width="72" style="margin-right: 4px;" src="/assets/urpay.png" /> -->
        </div>
        <svg class="arrow" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8887 7.79289C15.2793 8.18342 15.2793 8.81658 14.8887 9.20711L12.0959 12L14.8887 14.7929C15.2793 15.1834 15.2793 15.8166 14.8887 16.2071C14.4982 16.5976 13.8651 16.5976 13.4745 16.2071L9.97453 12.7071C9.58401 12.3166 9.58401 11.6834 9.97453 11.2929L13.4745 7.79289C13.8651 7.40237 14.4982 7.40237 14.8887 7.79289Z" fill="#777E91"/></svg>
      </div>
      <div class="action_item" (click)="setPaymentType('urpay')">
        <div style="display: flex; justify-content: flex-start; align-items: center;">
          <!-- <img width="72" src="/assets/urpay.png" /> -->
          <img width="72" src="/assets/urpay.png" />
        </div>
        <svg class="arrow" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8887 7.79289C15.2793 8.18342 15.2793 8.81658 14.8887 9.20711L12.0959 12L14.8887 14.7929C15.2793 15.1834 15.2793 15.8166 14.8887 16.2071C14.4982 16.5976 13.8651 16.5976 13.4745 16.2071L9.97453 12.7071C9.58401 12.3166 9.58401 11.6834 9.97453 11.2929L13.4745 7.79289C13.8651 7.40237 14.4982 7.40237 14.8887 7.79289Z" fill="#777E91"/></svg>
      </div>
    </div>
  </div>

  <!-- transfer -->
  <div *ngIf="type == 'transfer' && payment_type != ''">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <h1>اختر طريقة التحويل</h1>
      <div class="square_button" (click)="editPaymentType()">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.25 22.9393C3.25 22.387 3.69772 21.9393 4.25 21.9393H20.25C20.8023 21.9393 21.25 22.387 21.25 22.9393V22.9393C21.25 23.4916 20.8023 23.9393 20.25 23.9393H4.25C3.69772 23.9393 3.25 23.4916 3.25 22.9393V22.9393Z" fill="#6529F7"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.9571 4.06059C16.5666 3.67007 15.9334 3.67007 15.5429 4.06059L14.6642 4.93924L18.25 8.52503L19.1287 7.64638C19.5192 7.25585 19.5192 6.62269 19.1287 6.23217L16.9571 4.06059ZM7.16425 12.4392L13.25 6.35345L16.8358 9.93924L10.75 16.025L7.16425 12.4392ZM5.75003 13.8535L5.25 14.3535V17.9393H8.83579L9.33582 17.4392L5.75003 13.8535ZM14.1287 2.64638C15.3003 1.47481 17.1997 1.47481 18.3713 2.64638L20.5429 4.81795C21.7145 5.98953 21.7145 7.88902 20.5429 9.06059L10.25 19.3535C9.87493 19.7286 9.36622 19.9393 8.83579 19.9393H5.25C4.14543 19.9393 3.25 19.0438 3.25 17.9393V14.3535C3.25 13.8231 3.46071 13.3143 3.83579 12.9393L14.1287 2.64638Z" fill="#6529F7"/></svg>
      </div>
    </div>
    <div class="actions_wrapper">
      <div class="action_item">

        <!-- bank -->
        <div *ngIf="payment_type == 'bank'" style="display: flex;flex-direction: column;">
          <p><b>حساب</b></p>
          <p>xxxx{{data.iban.slice(-4)}}</p>
        </div>

        <!-- stc pay -->
        <div *ngIf="payment_type == 'stc_pay'" style="display: flex;flex-direction: column;">
          <p><b>STCPAY</b></p>
          <p>xxxx{{data.stc_pay.slice(-4)}}</p>
        </div>

        <div></div>
      </div>
    </div>
    <div (click)="askForPayment()" class="action_button full primary">تأكد التحويل</div>
  </div>

  <!-- create_receipt -->
  <div *ngIf="type == 'create_receipt'">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <h1>إنشاء فاتورة</h1>
    </div>
    <div class="actions_wrapper">
      <p>قيمة الفاتورة</p>
      <input type="number" [(ngModel)]="receipt_amount" class="action_item" placeholder="مثال: 500">
      <p>ملاحظات</p>
      <textarea [(ngModel)]="receipt_details" class="action_item"></textarea>
    </div>
    <div (click)="createReceipt()" class="action_button full primary">إنشاء وإرسال الفاتورة</div>
  </div>

  <!-- join_as_designer -->
  <div *ngIf="type == 'join_as_designer'">

    <div *ngIf="request_status == '' || request_status == 'loading'" [@fade]>
      <img width="100" class="gif_img" src="/assets/join.gif" />
      <div style="display: flex;justify-content: center; align-items: center;">
        <h1>طلب الإنضمام لدزاينر كمصمم</h1>
      </div>
      <div class="actions_wrapper">
        <p>الاسم</p>
        <input type="text" [(ngModel)]="joiner_name" class="action_item" placeholder="">
        <p>البريد الإلكتروني</p>
        <input type="text" [(ngModel)]="joiner_email" class="action_item" placeholder="">
        <p>رابط ملف الأعمال</p>
        <input type="text" [(ngModel)]="joiner_url" class="action_item" placeholder="">
      </div>
      <div (click)="addJoinAsDesigner()" class="action_button full primary">
        <ion-spinner style="color: white;" *ngIf="request_status == 'loading'"></ion-spinner>
        <span *ngIf="request_status != 'loading'">ارسال</span>
      </div>
    </div>

    <!-- success -->
    <div *ngIf="request_status == 'success'" [@fade]>
      <img width="160" class="gif_img" src="/assets/check.gif" />
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <h1>تم ارسال الطلب بنجاح!</h1>
        <p>سيتم الرد على رسالتك في أقرب وقت</p>
      </div>
    </div>


  </div>

    <!-- hire_request -->
    <div *ngIf="type == 'hire_request'">
      <!-- idle or loading -->
      <div *ngIf="request_status == '' || request_status == 'loading'" [@fade]>
        <img width="100" class="gif_img" src="/assets/join.gif" />
        <div style="display: flex;justify-content: center;align-items: center;">
          <h1 style="margin: 0;">طلب توظيف</h1>
          <div class="hire_designer_div">
            <img class="hire_img" [src]="api_path + '/' + data?.hire_img" />
            <p class="hire_name">{{util.decode(data?.hire_name)}}</p>
          </div>
        </div>
        <div class="actions_wrapper">
          <p>البريد الإلكتروني</p>
          <input type="text" [(ngModel)]="requester_email" class="action_item" placeholder="">
          <p>الرسالة</p>
          <textarea [(ngModel)]="requester_msg" id="requester_msg" class="action_item"></textarea>
        </div>
        <div (click)="submitHireRequest()" class="action_button full primary">
          <ion-spinner style="color: white;" *ngIf="request_status == 'loading'"></ion-spinner>
          <span *ngIf="request_status != 'loading'">ارسال</span>
        </div>
      </div>

      <!-- success -->
      <div *ngIf="request_status == 'success'" [@fade]>
        <img width="160" class="gif_img" src="/assets/check.gif" />
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <h1>تم ارسال الطلب بنجاح!</h1>
          <p>سيتم الرد على رسالتك في أقرب وقت</p>
        </div>
      </div>
    </div>
  
</ion-content>
