import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { services_templates, skills_categories_mapping, UtilService } from '../services/util.service';
import { LoadingComponent } from '../components/loading/loading.component';
import { ModalComponent } from '../components/modal/modal.component';
import { AlertController, ModalController, NavController, RangeCustomEvent } from '@ionic/angular';
import { HttpService } from '../services/http.service';
import { Camera, CameraResultType } from '@capacitor/camera';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { api_path } from 'src/environments/environment';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.page.html',
  styleUrls: ['./add-service.page.scss'],
})
export class AddServicePage implements OnInit {

  @ViewChild(LoadingComponent) loadingComponent;
  @ViewChild(ModalComponent) modalComponent;
  user;
  service;
  type = 'add';
  api_path = api_path
  services_templates = services_templates

  skills_mapping = []
  selected_service_index = -1;
  selected_service = {};
  selected_skill = ""

  title = "";
  description = "";
  deliverables = [""];
  batches = [""];
  price = 0;

  service_date_index = 0;

  packages = ["1"]
  service_status = true;
  service_images = [];
  service_images_urls = [];
  edit_service_images = [];
  edit_service_images_urls = [];

  is_input_disabled = true;

  current_range_value = 0;

  constructor(
    private alertController: AlertController,
    private httpService: HttpService,
    private modalController: ModalController,
    public util: UtilService,
    public userService: UserService,
    public navCtrl: NavController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.userService.getUserData().then(res => {
      this.user = JSON.parse(res.value);
      if (this.user == null) {
        this.router.navigate(['/login']);
        return;
      }
      // console.log("this.user");
      // console.log(this.user);

      if (this.service != null) {
        // console.log("this.service")
        // console.log(this.service)
        this.populateServiceData();
        this.type = 'edit';
        this.is_input_disabled = false;
      }

      this.userService.getSkillsData().then(res => {
        var skills_categories_mapping = JSON.parse(res.value);
        // this.skills_mapping = Object.keys(skills_categories_mapping).map((key) => skills_categories_mapping[key]);
        this.skills_mapping = Object.keys(skills_categories_mapping).map((key) => skills_categories_mapping[key]);
      });
    })
  }

  populateServiceData() {
    console.log("populateServiceData")
    this.title = this.service.Services.title;
    this.description = this.service.Services.description;
    // this.batches = this.service.Services.batches.split(",");
    this.batches = this.service.ServiceBatches.map(b => (b.percentage));
    setTimeout(() => {
      this.batches.forEach((b, i) => {
        document.getElementById("batch-"+i).setAttribute("value", b);
      });
    }, 100);

    this.deliverables = this.service.ServicePackages[0].description.split(",");
    setTimeout(() => {
      this.deliverables.forEach((d, i) => {
        document.getElementById("deliverable-"+i).setAttribute("value", d);
      });
    }, 100);

    if (this.service.Services.status == "Active") {
      this.service_status = true;
    } else {
      this.service_status = false;
    }

    switch (this.service.ServicePackages[0].days)
    {
      case 7:
        this.service_date_index = 0;
        break;
      case 30:
        this.service_date_index = 1;
        break;
      case 60:
        this.service_date_index = 2;
        break;
      default:
        this.service_date_index = 0;
        break;
    }

    this.service_images = this.service.ServiceImages.map((image) => image.File.url);
    this.service_images_urls = this.service.ServiceImages.map((image) => image.File.thumb_url);
    // console.log("service_images_urls")
    // console.log(this.service_images_urls)
    
    this.deliverables = this.service.ServicePackages[0].description.split(",");
    this.price = this.service.ServicePackages[0].price;
  }

  isServiceChecked() {
    if (this.service == null) {
      return true;
    } else {
      return this.service.Services.status == "Active";
    }
  }

  handleSkillChange(ev) {
    this.selected_skill = ""+ev.target.value;
    // console.log("this.selected_skill")
    // console.log(this.selected_skill)
  }

  handleServiceChange(ev) {
    this.selected_service_index = ev.target.value
    this.selected_service = services_templates[this.selected_service_index];
    // console.log("this.selected_service")
    // console.log(this.selected_service)

    this.is_input_disabled = false;

    this.populateServiceInputs(this.selected_service);
  }

  populateServiceInputs(service) {
    this.title = service.title;
    this.description = service.description;
    this.deliverables = service.deliverables;
    setTimeout(() => {
      this.deliverables.forEach((d, i) => {
        document.getElementById("deliverable-"+i).setAttribute("value", d);
      });
    }, 100);

    this.price = service.price;

    // set default batches
    this.current_range_value = 100;
    this.setRangeValues();
  }

  setActiveDateIndex(i) {
    this.service_date_index = i;

    if (this.service_date_index == 2) {
      document.getElementById("custom-days-div").style.display = "block";
    } else {
      document.getElementById("custom-days-div").style.display = "none";
    }
  }

  addServiceDeliverable() {
    this.deliverables.push("");
  }

  removeServiceDeliverable(i) {
    this.deliverables.splice(i, 1);
  }

  addNewBatchInput() {
    if (this.batches.length < 3) {
      this.batches.push("");
    } else {
      alert('لا يمكن اضافة اكثر من 3 دفعات');
    }
  }

  removeBatchInput(i) {
    this.batches.splice(i, 1);
  }

  async presentConfirmService(type) {

    var msg = "تأكيد إضافة الخدمة ؟"
    if (type == 'edit') {
      msg = "تأكيد تعديل الخدمة ؟"
    } else if (type == 'delete') {
      msg = "تأكيد حذف الخدمة ؟"
    }

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'تأكيد ؟',
      message: msg,
      buttons: [
        {
          text: 'انتظر',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'نعم',
          id: 'confirm-button',
          handler: () => {
            if (type == 'delete') {
              this.deleteService();
            } else {

              var input_items = this.getInputItems();
              var invalid_items = this.validateBeforeAdd(input_items);
              invalid_items.items = [ ...new Set(invalid_items.items) ];
              if (invalid_items.status == true) {
                if (type == 'edit') {
                  this.processService(input_items, 'edit');
                } else {
                  this.processService(input_items, 'add');
                }
              } else {
                this.presentIncompleteService(invalid_items.items)
              }

            }
          }
        }
      ]
    });

    await alert.present();
  }

  getInputItems() {
    var input_items = {
      title: this.title,
      description: this.description,
      deliverables: "",
      batches: "",
      price: this.price,
      days: 1,
      packages: this.packages,
      service_status: "Active",
      service_images: this.service_images,
      service_images_urls: this.service_images_urls,
      edit_service_images: this.edit_service_images,
      edit_service_images_urls: this.edit_service_images_urls,
    };

    if (this.service_status == false) {
      input_items.service_status = "Inactive";
    }

    var batch1 = this.current_range_value/100;
    var batch2 = 1-batch1;
    input_items.batches = `${batch1},${batch2}`;
    // this.batches.forEach((batch, index) => {
    //   input_items.batches += (<HTMLInputElement>document.getElementById(`batch-${index}`)).value + ",";
    // });
    // input_items.batches = input_items.batches.slice(0, -1);

    this.deliverables.forEach((deliverable, index) => {
      input_items.deliverables += (<HTMLInputElement>document.getElementById(`deliverable-${index}`)).value + ",";
    });
    input_items.deliverables = input_items.deliverables.slice(0, -1);

    switch (this.service_date_index) {
      case 0:
        input_items.days = 7;
        break;
      case 1:
        input_items.days = 30;
        break;
      case 2:
        input_items.days = parseInt((<HTMLInputElement>document.getElementById(`custom-days-input`)).value);
        break;
      default:
        input_items.days = 7;
        break;
    }

    return input_items;
  }

  validateBeforeAdd(input_items) {

    var invalid_items = {
      status: true,
      items: [],
    };

    if (input_items.title == '') {
      // alert('يجب ادخال عنوان للخدمة');
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال عنوان للخدمة');
    }
    if (input_items.description == '') {
      // alert('يجب ادخال شرح للخدمة');
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال شرح للخدمة');
    }
    if (input_items.deliverables == '') {
      // alert('يجب ادخال مخرجات الخدمة');
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال مخرجات الخدمة');
    }
    if (input_items.price == null || input_items.price == '' || input_items.price == '0' || input_items.price == 0) {
      // alert('يجب ادخال مخرجات الخدمة');
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال سعر الخدمة');
    }
    if (input_items.batches == '') {
      // alert('يجب ادخال دفعات الخدمة');
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال دفعات الخدمة');
    }
    console.log("input_items.batches")
    console.log(input_items.batches)
    return
    // check if days are not a number
    if (isNaN(input_items.days)) {
      // alert('يجب ادخال دفعات الخدمة');
      invalid_items.status = false;
      invalid_items.items.push('يجب ان يكون مدة التسليم رقماً');
    }

    // join batches by , and sum the numbers
    var batches_sum = 0;
    input_items.batches.split(',').forEach((batch) => {
      batch = parseInt(batch);
      let price = parseInt(input_items.price);
      batches_sum += batch/price*100;
    });
    if (batches_sum != 100) {
      // alert('يجب ادخال دفعات الخدمة');
      invalid_items.status = false;
      invalid_items.items.push('يجب ان يكون مجموع الدفعات 100%');
    }
    if (input_items.packages.length == 0) {
      // alert('يجب اضافة باقة واحدة على الأقل');
      invalid_items.status = false;
      invalid_items.items.push('يجب اضافة باقة واحدة على الأقل');
    }
    // if (input_items.service_images.length == 0) {
    //   // alert('يجب اضافة صورة واحدة على الأقل');
    //   invalid_items.status = false;
    //   invalid_items.items.push('يجب اضافة صورة واحدة على الأقل');
    // }


      // this.packages.forEach(p_index => {
      //   var description = (<HTMLInputElement>document.getElementById(`pkg-description-${p_index}`)).value; ;
      //   var price = (<HTMLInputElement>document.getElementById(`pkg-price-${p_index}`)).value; ;
      //   var days = (<HTMLInputElement>document.getElementById(`pkg-days-${p_index}`)).value; ;
  
      //   if (description == '') {
      //     // alert('يجب ادخال شرح للباقة');
      //     invalid_items.status = false;
      //     invalid_items.items.push('يجب ادخال شرح للباقة');
      //   }
      //   if (price == '') {
      //     // alert('يجب ادخال سعر للباقة');
      //     invalid_items.status = false;
      //     invalid_items.items.push('(أرقام انجليزية) يجب ادخال سعر للباقة');
      //   }
      //   if (!this.util.containsOnlyNumbers(price)) {
      //     // alert('لازم السعر يتكون من أرقام فقط');
      //     invalid_items.status = false;
      //     invalid_items.items.push('لازم السعر يتكون من أرقام فقط');
      //   }
      //   if (days == '') {
      //     // alert('يجب ادخال مدة تسليم المشروع');
      //     invalid_items.status = false;
      //     invalid_items.items.push('(أرقام انجليزية) يجب ادخال مدة تسليم المشروع');
      //   }
      //   if (!this.util.containsOnlyNumbers(days)) {
      //     // alert('لازم المدة يتكون من أرقام فقط');
      //     invalid_items.status = false;
      //     invalid_items.items.push('لازم المدة يتكون من أرقام فقط');
      //   }
      //   if (this.service_images_urls.length > 5) {
      //     // alert('لازم المدة يتكون من أرقام فقط');
      //     invalid_items.status = false;
      //     invalid_items.items.push('لا يمكن رفع اكثر من ٥ تصاميم');
      //   }
      // });
      return invalid_items;
  }

  processService(input_items, type) {

    if (type == 'add') {
      this.loadingComponent.show('جاري اضافة الخدمة');
    } else if (type == 'edit') {
      this.loadingComponent.show('جاري تعديل الخدمة');
    }

    const body = {
      "title": input_items.title,
      "description": input_items.description,
      "skill_id": '1',
      "status": input_items.service_status,
    }

    // console.log("body: " + JSON.stringify(body));

    var url = `v1/services/createServiceOnly`;
    if (type == 'edit') {
      url = `v1/services/updateServiceOnly?service_id=${this.service.Services.id}`;
    }

    if (type == 'add') {


      this.httpService.doPost(url, body, this.user.jwt).then((res) => {
        if (res.status == 200) {
          var service_id = res.data.data.id;
          this.processPackages(service_id, input_items, 'add');
        } else if (res.status == 401) {
          this.util.goToHome(this.user.type);
        }
      })
        .catch(err => {
          this.loadingComponent.hide();
        })


    } else {


      this.httpService.doPut(url, body, this.user.jwt).then((res) => {
        if (res.status == 200) {
          var service_id = res.data.data.id;
          this.processPackages(service_id, input_items, 'edit');
        } else if (res.status == 401) {
          this.util.goToHome(this.user.type);
        }
      })
        .catch(err => {
          this.loadingComponent.hide();
        })


    }
    
  }

  processPackages(service_id, input_items, type) {

    // split batches to array
    var batches = input_items.batches.split(',');
    var batches_array = [];
    batches.forEach(batch => {
      var batch_obj = {
        "percentage": batch/100,
        "status": "Unpaid",
        "title": ""
      }
      batches_array.push(batch_obj);
    });

    // var packages_list = [];
    

    // console.log("body: " + JSON.stringify(body));
    var body = null;

    var url = `v1/services/createServicePackage`;
    body = {
      "service_id": service_id,
      "packages": [
        {
          "description": input_items.deliverables,
          "price": input_items.price,
          "days": input_items.days,
          "batches": batches_array
        }
      ]
    }

    if (type == 'edit') {
      url = `v1/services/updateServicePackage?package_id=${this.service.ServicePackages[0].id}`;
      body = {
        "description": input_items.deliverables,
        "price": input_items.price,
        "days": input_items.days,
        "batches": batches_array
      }
    }

    if (type == 'add') {

      this.httpService.doPost(url, body, this.user.jwt).then((res) => {
        if (res.status == 200) {
          if (this.service_images.length > 0) {
            this.uploadServiceImages(service_id);
          } else {
            this.navCtrl.navigateBack(['/service']);
          }
        } else if (res.status == 401) {
          this.util.goToHome(this.user.type);
        }
      })
        .catch(err => {
          this.loadingComponent.hide();
        })


    } else { // edit package


      this.httpService.doPut(url, body, this.user.jwt).then((res) => {
        if (res.status == 200) {
          if (this.edit_service_images.length > 0) {
            this.uploadServiceImages(service_id);
          } else {
            this.navCtrl.navigateBack(['/service']);
          }
        } else if (res.status == 401) {
          this.util.goToHome(this.user.type);
        }
      })
        .catch(err => {
          this.loadingComponent.hide();
        })

    }
  }

  addPackage(service_id, index) {

    var description = document.getElementById(`pkg-description-${index}`).textContent;
    var price = document.getElementById(`pkg-price-${index}`).textContent;
    var days = document.getElementById(`pkg-days-${index}`).textContent;

    if (description != '') {
      alert('لازم تكتب شرح للباقة');
      return;
    }
    if (price != '') {
      alert('لازم سعر للباقة');
      return;
    }
    if (days != '') {
      alert('لازم تكتب مدة تسليم المشروع');
      return;
    }

    description = encodeURIComponent(description);

    const body = {
      "description": description,
      "price": price,
      "service_id": service_id,
      "days": days
    }

    console.log("body: " + JSON.stringify(body));

    var url = `v1/services/createServicePackage`;
    // // console.log("body: " + JSON.stringify(body));
    this.httpService.doPost(url, body, this.user.jwt).then((res) => {
      // console.log("res");
      // console.log(res);
      if (res.status == 200) {

        // this.user.display_name = decodeURIComponent(display_name);
        // this.modalController.dismiss({
        //   // 'display_name': this.display_name,
        // });
      } else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }
    })
      .catch(err => {
        // this.modalComponent.show("خلل عند ارسال الطلب" + JSON.stringify(err));
      })
  }

  addNewPackageRow() {
    if (this.packages.length >= 3) {
      alert("لا يمكن اضافة اكثر من ٣ باقات");
      return;
    } else {
      this.packages.push((this.packages.length+1).toString());
    }
  }

  removePackage(index) {
    this.packages.splice(index, 1);
  }

  async promptAddImageToService() {
    // const image = await Camera.getPhoto({
    const images = await Camera.pickImages({
      quality: 100
    });

    images.photos.forEach(image => {
      // console.log("image")
      // console.log(image)
      
      var url = image.webPath;
      var image_path = image.path;

      if (!this.util.isMobile()) {
        image_path = image.webPath;
      }

      if (this.type == 'add') {
        this.service_images_urls.push(url);
      } else {
        this.edit_service_images_urls.push(url);
      }
      this.util.fileToBlob(image_path).then((blob) => {
        if (this.type == 'add') {
          this.service_images.push(blob);
        } else {
          this.edit_service_images.push(blob);
        }
      })
      // this.util.fileToBase64(image.path).then((base64) => {
      //   this.service_images.push(base64);
      // });
    });

    if (this.service_images_urls.length + this.edit_service_images_urls.length > 10) {
      alert("لا يمكن رفع اكثر من ١٠ تصاميم");
    }

  };

  deleteService() {

    // console.log("body: " + JSON.stringify(body));

    var url = `v1/services/deleteService?service_id=${this.service.Services.id}`;

    this.httpService.doDelete(url, this.user.jwt).then((res) => {
      if (res.status == 200) {
        this.modalController.dismiss({});
      }
    })
      .catch(err => {
        this.loadingComponent.hide();
      })
    
  }

  uploadServiceImages(service_id) {

    this.loadingComponent.show('جاري رفع التصاميم ');
    
    var url = `v3/file/uploadServiceImages?service_id=${service_id}`;

    var si = this.service_images;
    if (this.type == 'edit') {
      si = this.edit_service_images;
    }

    this.httpService.uploadFiles(url, this.user.jwt, si).then((res) => {

      if (res.status == 200) {
        // console.log("uploadServiceImage res")
        // console.log(res);
        this.modalController.dismiss({"update": true});
      }

    })
      .catch(err => {
        console.log("uploadServiceImage err")
        console.log(err)
      }).finally(()=>{
        this.loadingComponent.hide();
      });
  }

  removeServiceImage(index) {
    
    if (this.type == 'add') {
      this.service_images.splice(index, 1);
      this.service_images_urls.splice(index, 1);
    } else {
      this.edit_service_images.splice(index, 1);
      this.edit_service_images_urls.splice(index, 1);
    }
  }

  back() {
    this.navCtrl.navigateBack(['/service']);
  }

  async presentIncompleteService(items) {

    var msg = ``

    msg += `
      <ul class='invalid_list'>
    `
    items.forEach(item => {
      msg += `<li>${item}</li>`
    });

    msg += `
      </ul>
    `

    const alert = await this.alertController.create({
      cssClass: 'incomplete-profile',
      header: 'معلومات الخدمة غير مكتملة',
      message: msg,
      buttons: []
    });

    await alert.present();
  }

  onPriceChange() {
    this.setRangeValues();
  }

  setServiceIndex(index) {
    this.selected_service_index = index;
    this.selected_service = services_templates[this.selected_service_index];
    this.is_input_disabled = false;
    this.populateServiceInputs(this.selected_service);
  }

  onIonChange(ev: Event) {
    this.current_range_value = (ev as RangeCustomEvent).detail.value as number;
    // console.log('ionChange emitted value:', this.current_range_value);
    this.setRangeValues();
  }

  setRangeValues() {
    var batch1 = this.price*(this.current_range_value/100);
    var batch2 = this.price*((100-this.current_range_value)/100);
    document.getElementById('batch1').innerHTML = batch1.toString();
    document.getElementById('batch2').innerHTML = batch2.toString();
  }

}
