import { Component, NgZone, enableProdMode } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { UtilService } from './services/util.service';

import { TranslateService } from '@ngx-translate/core'; // add this

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  platform = Capacitor.getPlatform();

  constructor(
    private router: Router,
    private zone: NgZone,
    private util: UtilService,
    private translate: TranslateService // add this
  ) {
    this.initializeApp();
    // enableProdMode();
    // if (this.util.isMobile()) {
    // }

    // console.log("hello " + this.platform);
    // if (this.platform == 'ios') {
    //   App.addListener('appUrlOpen', data => {
    //     if (data.url.indexOf('dzyniapp://') > -1) {
    //       Browser.close();
    //     }
    //   });
    // }
    // SplashScreen.show({
    //   showDuration: 2000,
    //   autoHide: true,
    // });
  }

  initializeApp() {
    // App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    //   this.zone.run(() => {
    //     // Example url: https://beerswift.app/tabs/tab2
    //     // slug = /tabs/tab2
    //     let path = event.url.split(".app").pop();

    //     // https://app.dzyni.com/profile/14

    //     if (path) {
    //       path = path.replace("https://app.dzyni.com/", "");
    //       // console.log("DEBUG: path" + path);

    //       this.router.navigateByUrl(path);
    //     }
    //   });
    // });

    this.translate.setDefaultLang('ar'); // add this
    // this.translate.setDefaultLang('en'); // add this
  }
}
