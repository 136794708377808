import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponent } from 'src/app/components/modal/modal.component';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { DzyniButtonComponent } from 'src/app/components/dzyni-button/dzyni-button.component';
import { ServiceItemComponent } from 'src/app/components/service-item/service-item.component';
import { RequestItemComponent } from 'src/app/components/request-item/request-item.component';
import { RatingItemComponent } from 'src/app/rating-item/rating-item.component';
import { MetricBoxComponent } from 'src/app/components/metric-box/metric-box.component';
import { CircularProgressComponent } from 'src/app/components/circular-progress/circular-progress.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { DesignerCardComponent } from 'src/app/components/designer-card/designer-card.component';
import { DesignerAiCardComponent } from 'src/app/components/designer-ai-card/designer-ai-card.component';
import { VerifyEmailComponent } from 'src/app/verify-email/verify-email.component';
import { DesignerCardHComponent } from 'src/app/designer-card-h/designer-card-h.component';
import { IonicModule } from '@ionic/angular';

// translation
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    ModalComponent,
    LoadingComponent,
    DzyniButtonComponent,
    ServiceItemComponent,
    RequestItemComponent,
    RatingItemComponent,
    MetricBoxComponent,
    CircularProgressComponent,
    HeaderComponent,
    FooterComponent,
    DesignerCardComponent,
    DesignerAiCardComponent,
    VerifyEmailComponent,
    DesignerCardHComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ar',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader), 
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    ModalComponent,
    LoadingComponent,
    DzyniButtonComponent,
    ServiceItemComponent,
    RequestItemComponent,
    RatingItemComponent,
    MetricBoxComponent,
    CircularProgressComponent,
    HeaderComponent,
    FooterComponent,
    DesignerCardComponent,
    DesignerAiCardComponent,
    VerifyEmailComponent,
    DesignerCardHComponent,
    TranslateModule
  ]
})
export class SharedModule { }
